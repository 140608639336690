export enum Filters {
  MyJobs = 'My Jobs',
  AllJobs = 'All Jobs',
  Lookahead = 'Lookahead'
}

export enum Filter_Shift {
  Shift_12 = '12 hrs',
  Shift_24 = '24 hrs',
  Shift_48 = '48 hrs',
  Shift_All = 'All'
  // Shift_7 = '7 days',
  // Shift_14 = '14 days'
}

export enum Filter_WbsPage{
  Update = 'Update',
  Lookahead = 'Lookahead'
}
