export const environment = {
  production: true,
  mode: "Production",
  LogonAzureAPI:
    "https://mobileturnaround-api-prod-dr-cvx.azurewebsites.net/api/login",
  BaseAPI: "https://mobileturnaround-api-prod-dr-cvx.azurewebsites.net/api",
  AuthenticateScope:
    "https://turnaroundms-test.azure.chevron.com/user_impersonation",
  TonotesRedirect:
    "https://tonotesweb-prod-dr-cvx.azurewebsites.net/#/home/TaPro/",
  PushNotificationHubPath: "mobileturnaround-nh-prod-cvx",
  PushNonnectionString:
    "https://turnar-p101-prod1-cvx.vault.azure.net/secrets/TaProPushNonnectionString/c0b3719f36124c75af4d0086cae69eeb",
  FCMSenderId: "380548788333",
  schemeName: "com.chevron.tonotes://",
  TCOInstanceId: ["2"],

  adtionalDbOptions: ["error"],

  // MSAL config
  appRegistryB2C: {
    clientId: "7fb4b6b8-b711-46e0-bad1-2c42653f70bf",
    redirectUri: "/",
    postLogoutRedirectUri: "/",
    names: {
      signUpSignIn: "B2C_1_v1_signupandsignin_fwe",
    },
    authorities: {
      signUpSignIn: {
        authority:
          "https://chevronb2c.b2clogin.com/chevronb2c.onmicrosoft.com/B2C_1_v1_signupandsignin_fwe",
      },
    },
    knownAuthorities: ["chevronb2c.b2clogin.com"],
  },
  // Insights
  aiKey: "b098e5d2-3fc6-41a5-9cfe-3d695b4ac321",
};
