import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { DynamicModule } from 'ng-dynamic-component';
import { ActivityEntityManager, ProjectEntityManager } from 'src/data/EntityManagerIndex';
import { environment } from 'src/environments/environment';
import { TokenInterceptor } from 'src/interceptors/token.interceptor';
import { MobileVersionServiceProvider } from 'src/providers/ondemand-services/mobileVersion-service';
import { SyncManager } from 'src/services/sync/SyncManager';
import { AnalyticsProvider } from 'src/shared/analytics/analytics';
import { AppVersionProvider } from 'src/shared/appVersion';
import { LoginProvider } from 'src/shared/login';
import { MTAErrorHandler } from 'src/shared/MTAErrorHandler';
import { SharedModule } from 'src/shared/shared.module';
import { ResourceLiteServiceProvider } from '../providers/ondemand-services/resourceLite-service';
import { SyncProvider } from '../services/sync/sync';
import { WbsProvider } from '../services/wbs/wbs';
import { DeeplinkProvider } from '../shared/deeplink/deeplink';
import { ComponentsModule } from './../components/components.module';
import { ActivationContextProvider } from './../shared/deeplink/activationContext';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { msalConfig, protectedResources } from './auth-config';
import { ActivitiesService } from 'src/services/wbs/activities.service';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const isIE = window.navigator.userAgent.indexOf('MSIE') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log( message );
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: new Map([
      [protectedResources.mobileInspectionsApi.endpoint, protectedResources.mobileInspectionsApi.scopes]
    ])
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [environment.appRegistryB2C.clientId, 'openid']
    }
  };
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    DynamicModule,
    HttpClientModule,
    SharedModule,
    ComponentsModule,
    CommonModule,
    MsalModule,
    NoopAnimationsModule, // Animations cause delay which interfere with E2E tests
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    HttpClient,
    ActivationContextProvider,
    DeeplinkProvider,
    ProjectEntityManager,
    ScreenOrientation,
    WbsProvider,
    ActivitiesService,
    AppVersionProvider,
    ActivityEntityManager,
    MobileVersionServiceProvider,
    AnalyticsProvider,
    SyncProvider,
    LoginProvider,
    SyncManager,
    ResourceLiteServiceProvider,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: MTAErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
		},
    {
			provide: MSAL_INSTANCE,
			useFactory: MSALInstanceFactory,
		},
		{
			provide: MSAL_GUARD_CONFIG,
			useFactory: MSALGuardConfigFactory,
		},
		{
			provide: MSAL_INTERCEPTOR_CONFIG,
			useFactory: MSALInterceptorConfigFactory,
		},

		MsalService,
		MsalGuard,
		MsalBroadcastService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
