import { SyncConfig } from 'src/services/sync/SyncConfig';
import { ActivityStartJustification, SyncLog2 } from '../EntityIndex';
import { BaseEntityManager } from './BaseEntityManager';
import { db } from 'src/services/indexDb.service';
import * as moment from 'moment';

export class ActivityStartJustificationEntityManager extends BaseEntityManager {
	entityType = ActivityStartJustification;
	entityTableName = 'activityStartJustification';

	async insertActivityStartJustificationData(
		activityStartJustifications: ActivityStartJustification[]
	) {
		await this.insertData(activityStartJustifications);
	}

	async getActivityStartJustificationByActivityId(
		activityId: number
	): Promise<ActivityStartJustification[]> {
	//	return new Promise<ActivityStartJustification[]>(
			//async (resolve, reject) => {
				try {
					const activityStartJustifications =
						await db.activityStartJustification
							.where('ActivityId')
							.equals(activityId)
							.sortBy('UpdatedDate');
					return(activityStartJustifications as ActivityStartJustification[]);
				} catch (e) {
					throw(e);
				}
	}
	//	);
	//}

	async getDeltaData(config: SyncConfig): Promise<ActivityStartJustification[] | any> {
    const activities = await db.activity
      .where('ProjectId')
      .equals(config.project)
      .toArray();

    const entities = await db.activityStartJustification
      .where('ActivityId')
      .anyOf(activities.map((a) => a.Id))
      .and(
        (j) =>
          moment(j.UpdatedDate).isAfter(moment(config.lastDownloadTime)) &&
          j.UpdatedById === +config.userId
      )
      .toArray();

    return entities;
	}

	async hasOfflineData(syncLogs: SyncLog2[]): Promise<Set<number>> {
		//return new Promise<Set<number>>(async (resolve, reject) => {
			try {
				const offlineProjects: Set<number> = new Set();
				for (const syncLog of syncLogs) {
					if (syncLog.LastDownloadTime && syncLog.LastDownloadTime !== '') {
						const activityStartJustificationList =
							await db.activityStartJustification
								.filter((asj) => {
									const updatedDate = moment(asj.UpdatedDate);
									return (
										updatedDate.isAfter(moment(syncLog.LastDownloadTime)) &&
										asj.UpdatedById == +syncLog.UserId
									);
								})
								.toArray();
						const activityList = await db.activity
							.where('Id')
							.anyOf(
								activityStartJustificationList.map((asj) => asj.ActivityId)
							)
							.and((a) => a.ProjectId === syncLog.ProjectId)
							.toArray();

						if (
							activityStartJustificationList.length > 0 &&
							activityList.length > 0
						) {
							offlineProjects.add(syncLog.ProjectId);
						} else {
							continue;
						}
					}
				}
				return(offlineProjects);
			} catch (e) {
				throw(e);
			}
		//});
	}
}
