import { NgModule, inject } from '@angular/core';
import { PreloadAllModules, Router, RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { dbGuard } from 'src/guard/DBGuard';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'notice',
		pathMatch: 'full',
	},
	{
		path: 'home',
		canActivate: [MsalGuard, dbGuard],
		loadChildren: () =>
			import('./../pages/home/home.module').then((m) => m.HomePageModule),
	},
	{
		path: 'settings',
		loadChildren: () =>
			import('./../pages/settings/settings.module').then(
				(m) => m.SettingsPageModule
			),
	},
	{
		path: 'notice',
		loadChildren: () =>
			import('../pages/notice/notice.module').then((m) => m.NoticePageModule),
      canActivate: [
        () => {
          const router = inject(Router);

          if (localStorage.getItem('isNoticeAccepted')) {
            router.navigate(['home']);
          }
        }
      ],
	},
	{
		path: 'welcome-slides',
		loadChildren: () =>
			import('../pages/welcome-slides/welcome-slides.module').then(
				(m) => m.WelcomeSlidesPageModule
			),
	},
	{
		path: 'wbs-category',
		loadChildren: () =>
			import('../pages/wbs-category/wbs-category.module').then(
				(m) => m.WbsCategoryPageModule
			),
	},
	{
		path: 'activities',
		loadChildren: () =>
			import('../pages/activities/activities.module').then(
				(m) => m.ActivitiesPageModule
			),
	},
	{
		path: 'psr',
		loadChildren: () =>
			import('../pages/psr/psr.module').then((m) => m.PsrPageModule),
	},
	{
		path: 'add-comment',
		loadChildren: () =>
			import('../pages/add-comment/add-comment.module').then(
				(m) => m.AddCommentPageModule
			),
	},
	{
		path: 'detail',
		loadChildren: () =>
			import('../pages/detail/detail.module').then((m) => m.DetailPageModule),
	},
	{
		path: 'justification',
		loadChildren: () =>
			import('../pages/justification/justification.module').then(
				(m) => m.JustificationPageModule
			),
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
