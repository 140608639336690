import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	AlertController,
	LoadingController,
	ToastController,
} from '@ionic/angular';
import { ActivityServiceProvider } from 'src/data/entity-service/activity-service';
import { BaseServiceProvider } from 'src/data/entity-service/base-service';
import { CrewServiceProvider } from 'src/data/entity-service/crew-service';
import { Crew, CrewAssignment, Project } from '../../data/EntityIndex';
import { CrewType } from './../../data/InternalTypes';
import { DateTimeServiceProvider } from 'src/data/entity-service/datetime-service';
import {
	ActivityStartJustificationServiceProvider,
	ActivityStartJustificationTypeServiceProvider,
	CrewAssignmentServiceProvider,
	FlattenActivityCodeServiceProvider,
	JustificationServiceProvider,
	UDFCodeValueServiceProvider,
	WBSCategoryServiceProvider,
} from 'src/data/entity-service/entityServiceIndex';
import { OBSServiceProvider } from 'src/data/entity-service/obs-service';
import { ProjectServiceProvider } from 'src/data/entity-service/project-service';
import { ProjectConfigServiceProvider } from 'src/data/entity-service/projectConfig-service';
import { RelationshipServiceProvider } from 'src/data/entity-service/relation-service';
import { RoleServiceProvider } from 'src/data/entity-service/role-service';
import { ShiftServiceProvider } from 'src/data/entity-service/shift-service';
import { UserServiceProvider } from 'src/data/entity-service/user-service';
import { WBSServiceProvider } from 'src/data/entity-service/wbs-service';
import { SyncLogEntityManager } from 'src/data/EntityManagerIndex';
import { MTAErrorType } from 'src/data/InternalTypes';
import { MTAError } from 'src/data/MTAError';
import { environment } from 'src/environments/environment';
import { DbService } from 'src/services/db.service';
import { AnalyticsProvider } from 'src/shared/analytics/analytics';
import { Constants } from 'src/shared/constants';
import { Guid } from 'src/shared/GUID';
import { LoadingControllerHandler } from 'src/shared/loading-controller-handler';
import { LoginProvider } from './../../shared/login';
import { SyncConfig } from './SyncConfig';
import { SyncQueryService } from './SyncQueryService';
import { NetworkService } from '../network.service';
import { db } from '../indexDb.service';

@Injectable()
export class SyncManager {
	ServiceAndEntityMap: Record<string, string> = {
		'project': 'project',
    'activity': 'activity',
    'activitycodeconfiguration': 'activitycodeconfiguration',
    'shifts': 'shifts',
    'wbscategory': 'wbscategory',
    'wbs': 'wbs',
    'relationship': 'relationship',
    'resource': 'resource',
    'udfcodevalue': 'udfcodevalue',
    'role': 'role',
    'crew': 'crew',
    'crewassignment': 'crewassignment',
    'user': 'user',
    'justification': 'justification',
    'obs': 'obs',
    'activitystartjustificationtypes': 'activitystartjustificationtype',
    'activitystartjustifications': 'activitystartjustification',
    'resourceassignment': 'resourceassignment',
    'flattenactivitycode': 'flattenactivitycode',
	};

	syncLogManager: SyncLogEntityManager;
	entityList: string[];
	entityServices: BaseServiceProvider[] = [];
	projectId: number;
	lastUploadTime: string;
	lastDownloadTime: string;
	isFullSync = false;
	totalSyncOperation: number;
	syncProgressStep: number;
	syncConfig: SyncConfig;
	phase: 'Uploading' | 'Downloading';
	loading: LoadingController;
	constructor(
		public http: HttpClient,
		private loginProvider: LoginProvider,
		private dbManager: DbService,
		private analytics: AnalyticsProvider,
		private toastController: ToastController,
		private loadingControllerHandler: LoadingControllerHandler,
		private alertController: AlertController,
		private network: NetworkService
	) {
		this.initEntityServices(http);
	}
	private syncProgress: number;

	private initEntityServices(http: HttpClient) {
		this.entityServices.push(new ProjectServiceProvider(http));
		this.entityServices.push(new ProjectConfigServiceProvider(http));
		this.entityServices.push(new ActivityServiceProvider(http));
		this.entityServices.push(new FlattenActivityCodeServiceProvider(http));
		this.entityServices.push(new ShiftServiceProvider(http));
		this.entityServices.push(new WBSCategoryServiceProvider(http));
		this.entityServices.push(new WBSServiceProvider(http));
		this.entityServices.push(new RelationshipServiceProvider(http));
		this.entityServices.push(new RoleServiceProvider(http));
		this.entityServices.push(new CrewServiceProvider(http));
		this.entityServices.push(new CrewAssignmentServiceProvider(http));
		this.entityServices.push(new UserServiceProvider(http));
		this.entityServices.push(new JustificationServiceProvider(http));
		this.entityServices.push(new OBSServiceProvider(http));
		this.entityServices.push(new ActivityStartJustificationTypeServiceProvider(http));
		this.entityServices.push(new ActivityStartJustificationServiceProvider(http));
		this.entityServices.push(new UDFCodeValueServiceProvider(http));
	}

	async sync(projectId?: number): Promise<any> {
    this.entityList = null;
    this.projectId = projectId;

    await this.loadingControllerHandler.dismissAllLoaders();
    await this.loadingControllerHandler.createLoading({
      mode: 'ios',
      message: 'Sync In Progress...',
      spinner: 'lines',
    });
    await this.loadingControllerHandler.present();

    try {
      await this.checkAuthentication();
      // TODO: temporarily removed app version check logic
      await this.checkAssignedProjects()
      await this.checkLookAheadStatus();
      this.initSyncProgressProperty();
      await this.uploadAndDownloadData();
      await this.complete();
      this.completeProgress();

      await this.loadingControllerHandler.dismissAllLoaders();
    } catch (err) {
      const newErr = err instanceof MTAError
        ? err
        : new MTAError(MTAErrorType.COMMON_SYNC_ERROR, err);

      if (newErr.type !== MTAErrorType.MULTIPLE_PROJECTS_ASSIGNED) {
        this.failedProgress(newErr);
      }

      await this.loadingControllerHandler.dismissAllLoaders();
      throw newErr;
    }
	}

	async presentFailAuthentication() {
		const alertController = await this.alertController.create({
			header: 'Info',
			subHeader: '',
			message:
				'You are not an authorized user or Password is incorrect. Please contact scheduler.',
			buttons: [
				{
					text: 'OK',
					handler: () => {},
				},
			],
		});
		alertController.present();
	}

	private async checkAuthentication(): Promise<void> {
    try {
      await this.loginProvider.login()
    } catch (err) {
      if (err && err.status === 403) {
        throw new MTAError(
          MTAErrorType.NOT_AUTHORIZED_USER,
          'You are not an authorized user. Please contact scheduler.',
          err
        );
      }

      throw new MTAError(
        MTAErrorType.AUTHENTICATION_ERROR,
        'Authentication error. Please try it later.',
        err
      );
    }
	}

	async checkAssignedProjects(): Promise<void> {
    let projects: Project[] = [];

    try {
      // call projects API to get the project assigned
      const projectProvider = new ProjectServiceProvider(this.http);
      this.syncConfig = this.getSyncConfig();
      projects = await projectProvider.get(
        `${environment.BaseAPI}/${projectProvider.serviceName}/${projectProvider.getProjects}`,
        projectProvider.getOptions(this.syncConfig)
      );
    } catch (err) {
      throw new MTAError(MTAErrorType.SERVICE_ERROR, err);
    }

    await this.dbManager.deleteProjectsNotAssignedWithQueryRunner(projects);

    if (projects?.length > 0) {
      await this._onProjectProviderGetResolve(projects);
    } else {
      throw new MTAError(
        MTAErrorType.NO_PROJECT_ASSIGNED,
        'No project is assigned to you.'
      );
    }
	}

	private async _onProjectProviderGetResolve(projects: Array<Project>): Promise<void> {
    if (!this.projectId) {
      if (projects.length === 1) {
        this.projectId = projects[0].Id;
        this.syncConfig.project = this.projectId;
      } else if (projects.length > 1) {
        // user has multiple projects assigned, ask user to pick up a project
        throw new MTAError(
          MTAErrorType.MULTIPLE_PROJECTS_ASSIGNED,
          projects
        );
      }
    }
		try {
			const log = await this.dbManager.getLastSyncLogWithQueryRunner(this.projectId);
			if (log) {
				this.isFullSync = false;
				this.lastDownloadTime = log.LastDownloadTime;
				this.lastUploadTime = log.LastUploadTime;
				this.syncConfig.lastDownloadTime = this.lastDownloadTime;
				this.syncConfig.lastUploadTime = this.lastUploadTime;
			} else {
				this.isFullSync = true;
				this.lastDownloadTime = '';
				this.lastUploadTime = '';
			}
		} catch (err) {
			throw new MTAError(MTAErrorType.DATABASE_ERROR, err);
		}
    if (!this.isFullSync) {
      // query what entities are changed
      try {
        const syncQueryService = new SyncQueryService(this.http);
        const syncConfig = new SyncConfig();
        syncConfig.lastDownloadTime = this.lastDownloadTime;
        syncConfig.project = this.projectId;

        this.entityList = await syncQueryService.download(syncConfig)
      } catch (err) {
        throw new MTAError(MTAErrorType.COMMON_SYNC_ERROR, err);
      }
    }
	}

	async complete(): Promise<void> {
		const dateTimeService = new DateTimeServiceProvider(this.http);
    const result = await dateTimeService.download(null);
    const serverTime = new Date(result);

    try {
      await this.dbManager.createSyncLogWithQueryRunner(
        this.projectId,
        new Date().toISOString(),
        serverTime.toISOString()
      )
    } catch (err) {
      throw new MTAError(
        MTAErrorType.DATABASE_ERROR,
        'Cannot create sync log.',
        err
      );
    }
	}

	async uploadAndDownloadData(): Promise<void> {
    this.phase = 'Uploading';
    const promises: Promise<void>[] = [];

    for (const entityService of this.entityServices) {
      promises.push(this.doUpload(entityService));
    }

    this.phase = 'Downloading';
    const entityList = this.entityList?.map((entityName) => entityName.toLowerCase())

    for (const entityService of this.entityServices) {
      let caseEntityList = this.ServiceAndEntityMap[entityService.serviceName.toLowerCase()];

      if (
        this.isFullSync ||
        entityList?.includes(caseEntityList) ||
        entityService.serviceName === 'FlattenActivityCode'
      ) {
        promises.push(this.doDownload(entityService));
      }
    }

    await Promise.all(promises);
	}

	async doUpload(entityService: BaseServiceProvider): Promise<any> {
		try {
			if (entityService.isUploadable()) {
				await entityService.upload(this.syncConfig);
        this.updateProgress(entityService.serviceName);
				this.syncProgress += this.syncProgressStep;
			}
		} catch (e) {
			throw new MTAError(
				MTAErrorType.COMMON_SYNC_ERROR,
				`upload service ${entityService.serviceName} error.`,
				e
			);
		}
	}

	async doDownload(entityService: BaseServiceProvider): Promise<void> {
		try {
      if (entityService.isDownloadable()) {
				const config = this.syncConfig;
				const result = await entityService.download(config);
        await new entityService._entityManger().bulkInsertWithQueryRunner(result);
        this.updateProgress(entityService.serviceName);
        this.syncProgress += this.syncProgressStep;
			}
		} catch (err) {
			throw new MTAError(
        MTAErrorType.COMMON_SYNC_ERROR,
        `download service ${entityService.serviceName} error.`,
        err
      );
		}
	}

	getSyncConfig(): SyncConfig {
		const config = new SyncConfig();
		config.syncId = Guid.newGuid().toString();
		config.isFullSync = this.isFullSync;
		config.lastDownloadTime = this.lastDownloadTime;
		config.lastUploadTime = this.lastUploadTime;
		config.project = this.projectId;
		config.cai = localStorage.getItem('CAI');
		config.userId = parseInt(localStorage.getItem('UserId'), 10);
		return config;
	}

	initSyncProgressProperty() {
		this.totalSyncOperation = 0;
		this.syncProgress = 0;

		for (const entityService of this.entityServices) {
			if (entityService.isDownloadable()) {
				this.totalSyncOperation += 1;
			}

			if (entityService.isUploadable()) {
				this.totalSyncOperation += 1;
			}
		}

		this.syncProgressStep = Math.floor(100 / this.totalSyncOperation);
	}

	updateProgress(entity: string) {
		const msg = `${this.syncProgress}% completed - ${this.phase} ${entity} data`;
		this.showLoadingMsg(msg);
	}

	completeProgress() {
		const msg = `Sync completed.`;
		this.showLoadingMsg(msg);
		this.showToastMsg(msg);
	}

	async hasOfflineData(projectId?: number): Promise<Set<number>> {
		const syncLogs = projectId
			? await this.dbManager.getLastSyncLog(projectId)
			: await this.dbManager.getLastSyncLogForProjects();

		const offlineDataProjects = new Set<number>();

		if (syncLogs?.length > 0) {
			for (const entityService of this.entityServices) {
				if (entityService.isUploadable()) {
					const ret = await new entityService._entityManger().hasOfflineData(syncLogs);
					ret?.forEach((p) => {
						offlineDataProjects.add(p);
						// not need check the project again
						syncLogs.forEach((sl, index) => {
							if (sl.ProjectId === p) {
								syncLogs.splice(index, 1);
							}
						});
					});
				}
			}

			return offlineDataProjects;
		}
		return null;
	}

	async failedProgress(err: MTAError) {
		const ping = await this.network.pingServer();
		const msg = `${this.phase} data failed at ${this.syncProgress}%.`;
		if (
			this.loadingControllerHandler.loadingController &&
			this.phase &&
			this.syncProgress
		) {
			this.loadingControllerHandler.loadingController.duration = 1500;
			this.loadingControllerHandler.loadingController.message = msg;
		}
		const toastMsg =
			ping === false
				? 'No Network available, Please try again later.'
				: 'Error. Sync Failed with error - ' +
				  JSON.stringify(err.params) +
				  ' - Please retry. If problem persists, call helpdesk.';
		if (!err.isPopup) {
			this.showToastMsg(toastMsg);
		}
		const errorDetail = Object.keys(MTAErrorType).find(
			(key) => MTAErrorType[key] === err.type
		);
		this.analytics.error(
			errorDetail + '-' + Constants.SyncError,
			err.params,
			err
		);
	}

	private async showLoadingMsg(msg: string) {
		if (this.loadingControllerHandler.loadingController) {
			this.loadingControllerHandler.loadingController.message = msg;
		}
	}

	async showToastMsg(msg: string) {
		const toast = await this.toastController.create({
			header: '',
			message: msg,
			position: 'bottom',
			duration: 3000,
		});
		await this.loadingControllerHandler.dismissAllLoaders();
		await toast.present();
	}

	async checkLookAheadStatus(): Promise<any> {
		const projectProvider = new ProjectServiceProvider(this.http);
		const ret = await projectProvider.get(
      `${environment.BaseAPI}/${projectProvider.serviceName}/${projectProvider.getProjects}/${this.projectId}/lookaheadstatus`,
      projectProvider.getOptions(this.syncConfig)
    );

    if (ret.LockStatus) {
      this.loadingControllerHandler.showAlert = false;
      throw new MTAError(
        MTAErrorType.LOOK_AHEAD_ERROR,
        `This project can't be sync because a lookahead has been triggered on portal. Please try again in a minute.`,
        'project is currently on sync.'
      );
    }
	}

	async getCrewAssignment(
		UserId: number,
		ProjectId: number
	): Promise<CrewAssignment[]> {
    let crewAssignmentList: CrewAssignment[]= [];

    if (this.network.networkStatus) {
      const projectProvider = new ProjectServiceProvider(this.http);
      crewAssignmentList = await projectProvider
        .get(`${environment.BaseAPI}/crewassignment/${ProjectId}`);
    } else {
      const retList = await db.crewAssignment
        .where('ProjectId')
        .equals(ProjectId)
        .toArray();
      const crewList = await db.crew
        .where('CrewId')
        .anyOf(retList.map((c) => c.CrewId))
        .toArray();

      crewAssignmentList = retList.map((crewAssignment) => {
        const crew = crewList.find((item) => item.CrewId === crewAssignment.CrewId);

        return {
          ...crewAssignment,
          Crew: crew as Crew,
        } as CrewAssignment;
      });
    }

    const userCrewAssignment = crewAssignmentList.filter((element) => element.UserId === UserId);
    const isUserCoReps = userCrewAssignment.some(c => c.Crew.Type === CrewType.CoReps);

    if (isUserCoReps) {
      const hasContractors = crewAssignmentList.some(
        (e) => (e.Crew.Type === CrewType.Crew1 || e.Crew.Type === CrewType.Crew2) && !e.IsDeleted
      );

      userCrewAssignment.forEach((c) => {
        c.HasNoContractors = !hasContractors;
      });
    }

    return userCrewAssignment
	}
}
