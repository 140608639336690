import { Component, EventEmitter, Injector } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import * as moment from 'moment';
import { MTAContainerComponent } from 'src/MTAContainerComponent';
import { toISOLocal } from 'src/shared/utility';

@Component({
  selector: 'app-activity-actual-start-finish-modal',
  templateUrl: './activity-actual-start-finish-modal.component.html',
  styleUrls: ['./activity-actual-start-finish-modal.component.scss'],
})
export class ActivityActualStartFinishModalComponent extends MTAContainerComponent {
  public initialDate: string;
  public finishDate: Date;
  public maxStartDate: string;
  public maxFinishDate: string;
  public minFinishDate: string;
  public dateFormat: string;
  public actualDisplayFormat: string;
  public percentCompleted: number;
  private finishDateEmitter: EventEmitter<Date>;
  private startDateEmitter: EventEmitter<Date>;
  

  constructor(
    public injector: Injector,
    private navParams: NavParams,
    private popoverCtrl: PopoverController
  ) {
    super(injector);
    this.startDateEmitter = this.navParams.data.startDateEmitter;
    this.finishDateEmitter = this.navParams.data.finishDateEmitter;
    this.percentCompleted = this.navParams.data.percentCompleted;
    const momentStartDate = moment(
      this.navParams.data?.startDate,
      'MM-DD-YYYY, hh:mm'
    );
    this.initialDate = momentStartDate.format('YYYY-MM-DDTHH:mm');
    const momentMaxStartDate = moment(
      this.navParams.data?.maxStartDate,
      'MM-DD-YYYY, hh:mm'
    );
    this.maxStartDate = momentMaxStartDate.toString();
    this.minFinishDate = this.navParams.data?.minFinishDate;
    this.maxFinishDate = this.navParams.data?.maxFinishDate;
  }

  async updateStartDate() {
    this.minFinishDate = toISOLocal(new Date(this.initialDate));
    this.startDateEmitter.emit(new Date(this.initialDate));
  }

  async updateFinishDate() {
    this.maxStartDate = toISOLocal(new Date(this.finishDate));
    this.finishDateEmitter.emit(this.finishDate);
  }
  async closePopover() {
    this.popoverCtrl.dismiss();
  }
}
