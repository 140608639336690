import { Injectable } from '@angular/core';
import { AlertController, LoadingController, LoadingOptions } from '@ionic/angular';
import { Guid } from './GUID';
@Injectable({
  providedIn: 'root',
})
export class LoadingControllerHandler {
  constructor(
    private _loadingController: LoadingController,
    private _alertController: AlertController
  ) {}

  public loadingController: HTMLIonLoadingElement = null;
  public alertController: HTMLIonAlertElement = null;
  public showAlert = true;

  public async createLoading(config: LoadingOptions): Promise<void> {
    this.loadingController = await this._loadingController.create(config);
  }

  public async present() {
    await this.loadingController.present();
  }

  public async dismissAllLoaders(): Promise<void> {
    await this.loadingController?.dismiss();
  }

  public async presentAlert(errorId: Guid) {
    this.alertController = await this._alertController.create({
      header: 'Technical Error',
      subHeader: 'Please contact helpdesk with the following ID:',
      message: errorId.toString(),
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.alertController = null;
          },
        },
      ],
    });
    await this.alertController.present();
  }
}
