import { Injectable } from '@angular/core';
import {
  ActivityPSRSnapshot,
  Project,
  WBS,
  Activity,
  UDFCodeValue,
  WBSCategory,
  FlattenActivityCode,
  CrewAssignment,
} from '../../data/EntityIndex';
import {
  ActivityEntityManager,
  WBSEntityManager,
} from '../../data/EntityManagerIndex';
import { Filters, Filter_Shift } from '../../data/Filters';
import { ActivityInternalStatus } from './../../data/ActivityInternalStatus';
import { RoleType } from './../../data/InternalTypes';
import { SyncManager } from '../../services/sync/SyncManager';
import { db } from '../indexDb.service';
import * as moment from 'moment';
import { keyBy } from 'src/shared/utility';

export interface IWbsResult {
  Id: number;
  ProjectId: number;
  Name: string;
  GlobalOrder: number;
  wbsCategoryName: string;
  wbsCategoryId: number;
  orderNumber: string;
  Priority: number;
  HasNoContractors?: boolean;
  IsReadOnly?: boolean;
  filteredActivityCount?: number;
  filteredActivities?: Array<any>;
  CrewId?: number;
}

export interface IWbsCategoryActivity extends Pick<Activity, 'StartDate' | 'DataDate'> {
  Id: number;
  wbsCategoryName: string;
  wbsCategoryId: number;
}

export type WorkOrderMap = Record<number, string>;
export type ActivityInternalStatusHelpers = {
  noUpdateIndex: number;
  tempList: ActivityInternalStatus[];
};

/*
  Generated class for the WbsProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class WbsProvider {
  projectId: number;
  crewFilter: Filters;
  role: RoleType;
  wbsCategoryActivities: IWbsCategoryActivity[];

  async initWbsCategoryActivities(
    projectId: number,
    role: RoleType,
    crews: CrewAssignment[],
    crewFilter: Filters
  ): Promise<void> {
    if (
      this.wbsCategoryActivities &&
      this.projectId === projectId &&
      this.crewFilter === crewFilter &&
      this.role === role
    ) {
      return;
    }

    this.projectId = projectId;
    this.crewFilter = crewFilter;
    this.role = role;

    const crewsIds = crews.map((c) => c.CrewId);
    const activitiesList = await this.#getActivityListForProject(projectId);
    const flattenActivityCodeList = await this.#getFlattenActivityCodes(
      crewsIds,
      crewFilter,
      role,
      activitiesList
    );
    const wbsList = await db.wbs
      .where('Id')
      .anyOf(activitiesList.map((a) => a.WbsId))
      .and((wbs) => wbs.ProjectId === projectId)
      .toArray();
    const wbsCategoryList = await db.wbsCategory
      .where('Id')
      .anyOf(wbsList.map((w) => w.WBSCategoryId))
      .toArray();

    this.wbsCategoryActivities = this._getActivitiesForWbsCategory(
      activitiesList,
      wbsList,
      wbsCategoryList,
      flattenActivityCodeList
    );
  }

  async getWbsCategories(shiftFilter = Filter_Shift.Shift_All): Promise<IWbsCategoryActivity[]> {
    let filteredByShift: IWbsCategoryActivity[] = [];

    if (shiftFilter === Filter_Shift.Shift_All) {
      filteredByShift = [...this.wbsCategoryActivities];
    } else {
      filteredByShift = this.wbsCategoryActivities.filter((activity) => {
        switch (shiftFilter) {
          case Filter_Shift.Shift_12:
            return moment(activity.StartDate).isSameOrBefore(
              moment(activity.DataDate).add(0.5, 'd')
            );
          case Filter_Shift.Shift_24:
            return moment(activity.StartDate).isSameOrBefore(
              moment(activity.DataDate).add(1, 'd')
            );
          case Filter_Shift.Shift_48:
            return moment(activity.StartDate).isSameOrBefore(
              moment(activity.DataDate).add(2, 'd')
            );
          default:
            return true;
        }
      });
    }

    filteredByShift.sort((a, b) => a.wbsCategoryName?.localeCompare(b.wbsCategoryName) ?? -1);
    const wbsCategoryNames = new Set();

    return filteredByShift.filter((wbsCategory) => {
      if (!wbsCategoryNames.has(wbsCategory.wbsCategoryName)) {
        wbsCategoryNames.add(wbsCategory.wbsCategoryName);
        return true;
      }

      return false;
    });
  }

  // init work order number for wbs
  async assignWorkOrderNumbers(WBSs: WBS[], projectId?: number): Promise<WBS[]> {
    const workOrders = await this._queryWorkOrderNumberForWBSs(projectId);

    for (const wbs of WBSs) {
      wbs.orderNumber = workOrders[wbs.Id];
    }

    return WBSs;
  }

  async getWBSActivityPropertiesForPSR(
    project: Project,
    shiftNumber: number
  ): Promise<ActivityPSRSnapshot[]> {
      const apsList = await db.activityPSRSnapshot
        .where({
          ProjectId: project.Id,
          ShiftNumber: shiftNumber,
        })
        .sortBy('WbsId');
      const activityList = await db.activity
        .where('Id')
        .anyOf(apsList.map((aps) => aps.ActivityId))
        .sortBy('StartDate');
      const returnedList: ActivityPSRSnapshot[] = apsList.reduce((acc, aps) => {
        const a = activityList.find(
          (activity) => activity.Id === aps.ActivityId
        );
        if (a) {
          acc.push({
            ActivityId: a.Id,
            ActivityIdText: a.ActivityId,
            Name: a.Name,
            ShiftNumber: aps.ShiftNumber,
            WbsId: aps.WbsId,
            P6PercentOrigin: aps.P6PercentOrigin,
            P6PercentNew: aps.P6PercentNew,
            InternalPercentOrigin: aps.InternalPercentOrigin,
            InternalPercentNew: aps.InternalPercentNew,
            InternalStatusOrigin: aps.InternalStatusOrigin,
            InternalStatusNew: aps.InternalStatusNew,
          });
        }
        return acc;
      }, []);

      returnedList.sort((a, b) => moment.utc(a.StartDate).diff(moment.utc(b.StartDate)))

      return returnedList;
  }

  private async _queryWorkOrderNumberForWBSs(projectId?: number): Promise<WorkOrderMap> {
    const activities = await db.activity
      .where('ProjectId')
      .equals(projectId ?? this.projectId)
      .distinct()
      .sortBy('WbsId');
    const flattenActivityCodes = await db.flattenActivityCode
      .where('ActivityId')
      .anyOf(activities.map((a) => a.Id))
      .toArray();

    return activities.reduce((workOrderMap: WorkOrderMap, activity) => {
      if (workOrderMap[activity.WbsId]) {
        return workOrderMap;
      }

      const fac = flattenActivityCodes.find((fac) => fac.ActivityId == activity.Id);

      if (fac?.Ewo) {
        workOrderMap[activity.WbsId] = fac.Ewo;
      }

      return workOrderMap;
    }, {});
  }

  private _getActivitiesForWbsCategory(
    activitiesList: Partial<Activity>[],
    wbsList: Partial<WBS>[],
    wbsCategoryList: Partial<WBSCategory>[],
    flattenActivityCodeList: Partial<FlattenActivityCode>[],
  ): IWbsCategoryActivity[] {
    const wbsMap = keyBy(wbsList, (wbs) => wbs.Id);
    const wbsCategoryMap = keyBy(wbsCategoryList, (wbsCategory) => wbsCategory.Id);
    const facMap = keyBy(flattenActivityCodeList, (fac) => fac.ActivityId);

    return activitiesList?.reduce((acc: IWbsCategoryActivity[], activity) => {
      const wbs = wbsMap?.[activity.WbsId];
      const wbsCategory = wbsCategoryMap?.[wbs.WBSCategoryId];

      if (wbs && facMap?.[activity.Id]) {
        acc.push({
          Id: wbs.Id,
          StartDate: activity.StartDate,
          DataDate: activity.DataDate,
          wbsCategoryName: wbsCategory?.Name,
          wbsCategoryId: wbs?.WBSCategoryId
        });
      }

      return acc;
    }, []);
  }

  async #getActivityListForProject(projectId: number): Promise<Activity[]> {
    const activitiesList = await db.activity
      .where('[ProjectId+Type]')
      .equals([projectId, 'TaskDependent'])
      .toArray();

    return activitiesList.filter((activity) => {
      return activity.Status !== 'Completed' && activity.PercentComplete !== 1;
    }) as Activity[];
  }

  async #getFlattenActivityCodes(
    crewsIds: number[],
    crewFilter: Filters,
    role: RoleType,
    activitiesList: Partial<Activity>[],
  ): Promise<Partial<FlattenActivityCode>[]> {
    const list = await db.flattenActivityCode
      .where('ActivityId')
      .anyOf(activitiesList.map((a) => a.Id))
      .toArray();

    return list.filter((fac) => {
      const base = !fac.TawExcluded

      if (crewFilter === Filters.AllJobs)  {
        return base;
      }

      if (role === RoleType.CR) {
        return base && crewsIds.includes(fac.CompanyRepId);
      }

      return (
        base && (crewsIds.includes(fac.MainCrewId) || crewsIds.includes(fac.SupportCrewId))
      );
    })
  }
}
