import { Component, Injector, Input } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { MTAContainerComponent } from '../../../MTAContainerComponent';

@Component({
	selector: 'app-percentage-bar-tablet',
	templateUrl: 'percentage-bar.component-tablet.html',
	styleUrls: ['./percentage-bar.component-tablet.scss'],
})
export class PercentageBarTabletComponent extends MTAContainerComponent {
	@Input()
	percentageArray: Array<number>;
	@Input()
	disabled: boolean;
	@Input()
	selectedValue: number;
	@Input()
	minValue: number;
	@Input()
	getStyle: (value) => string;

	constructor(public injector: Injector, private toastCtrl: ToastController) {
		super(injector);
	}

	async changeValue(value) {
		if (value >= this.minValue * 100) {
			this.selectedValue = value;
			this.something.emit({ operation: 'changeValue', params: value });
		} else {
			const toast = await this.toastCtrl.create({
				message:
					'You cannot select value less than Primavera percent complete.',
				duration: 2000,
				position: 'top',
				cssClass: 'toast-message-purple',
			});
			toast.present();
			this.selectedValue = this.minValue * 100;
			this.something.emit({ operation: 'changeValue', params: this.selectedValue });
		}
	}
}
