import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild} from '@angular/core';
import { Activity, Project } from 'src/data/EntityIndex';
import { MTAContainerComponent } from 'src/MTAContainerComponent';
import { ActivityInternalStatus } from '../../data/ActivityInternalStatus';
import { ActivityEntityManager } from '../../data/EntityManagerIndex';
import { PercentageBarComponent } from './../percentage-bar/percentage-bar.component';
import { TriToggleComponent } from './../tri-toggle/tri-toggle.component';
import { ActivityCellPhoneComponent } from './phone/activity-cell.component-phone';
import { ActivityCellTabletComponent } from './tablet/activity-cell.component-tablet';


@Component({
  selector: 'app-activity-cell',
  templateUrl: './activity-cell.component.html',
  styleUrls: ['./activity-cell.component.scss'],
})
export class ActivityCellComponent extends MTAContainerComponent implements OnInit {
  hasProjectedDate = true;
  private _hasJustification = false;
  @Input() activity: Activity;
  @Input() isCompanyRep = false;
  @Input() crewsIdStr: string;
  @ViewChild(TriToggleComponent) statusComponent: TriToggleComponent;
  isCommentAvailable = false;
  private _rejectComment: any = null;
  get rejectComment(): any {
    return this._rejectComment;
  }
  set rejectComment(value: any) {
    this._rejectComment = value;
  }

  @ViewChild(PercentageBarComponent) percentageBar: PercentageBarComponent;
  @Output() changedActivity: EventEmitter<[Activity, string, any]> = new EventEmitter();
  @Output() updatePercent: EventEmitter<[Activity]> = new EventEmitter();
  @Output() viewToNotesOptions: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    public injector: Injector,
    private activityManger: ActivityEntityManager
  ) {
    super(injector);
  }

  ngOnInit() {
    this.subComponentJson = { tablet: ActivityCellTabletComponent, phone: ActivityCellPhoneComponent };
    this.subComponent = this.getComponentType();
    this.hasCrewId();

    this.inputs = {
      activity: this.activity,
      crewsIdStr: this.crewsIdStr,
      isCompanyRep: this.isCompanyRep,
      getActivityStatusImg: () => {
        return this.getActivityStatusImg();
      },
      getClassByStatus: () => {
        return this.getClassByStatus();
      },
      isTranslatedActivity:
         this.isTranslatedActivity,
    };
    this.outputs = {
      something: data => {
        const opt = data.operation;
        const params = data.params;
        switch (opt) {
          case 'viewActivity':
            this.viewActivity();
            break;
          case 'changePercentage':
            this.changePercentage(params);
            break;
          case 'changeStatus':
            this.changeStatus(params);
            break;
          case 'cantWork':
            this.cantWork();
            break;
          case 'navigateTONotes':
            this.navigateTONotes(params);
            break;
          case 'updateStartFinishDate':
            this.updateStartFinishDate(params);
            break;
          case 'updateProgress':
            this.updateProgress(params);
            break;
        }
      }
    };
  }

  get hasJustification() {
    return this._hasJustification;
  }
  set hasJustification(value) {
    this._hasJustification = value;
  }

  get isTranslatedActivity() {
    const selectedLanguage = localStorage.getItem('language');
    return (selectedLanguage === 'ru' && this.activity.UDFTypeTitle === 'Russian Description') ||
              (selectedLanguage === 'kaz' && this.activity.UDFTypeTitle === 'Kazakh Description');
  }

  hasCrewId() {
    if (
      !this.crewsIdStr.includes(this.activity.MainCrewId?.toString()) &&
      !this.crewsIdStr.includes(this.activity.SupportCrewId?.toString()) &&
      !this.crewsIdStr.includes(this.activity.CompanyRepId?.toString())
    ) {
      this.activity.isReadOnly = true;
    }
  }

  viewActivity() {
    // to view activity details
    this.changedActivity.emit([this.activity, 'v', null]);
  }

  cantWork() {
    this.changedActivity.emit([this.activity, 'c', null]);
  }

  changePercentage(value: number) {
    this.changedActivity.emit([this.activity, 'p', value]);
    this.inputs.activity = this.activity;
  }

  changeStatus(status: any) {
    this.changedActivity.emit([this.activity, 's', status]);
    this.inputs.activity = this.activity;
  }

  updateStartFinishDate(activity: Activity) {
    this.changedActivity.emit([this.activity, 'd', null]);
    this.inputs.activity = this.activity;
}

  getActivityStatusFromToggleStatus(status: 'true' | 'false' | 'null') {
    if (status === 'true') {
      return ActivityInternalStatus.Approved;
    } else if (status === 'false') {
      return ActivityInternalStatus.Rejected;
    } else {
      return ActivityInternalStatus.Pending;
    }
  }

  updateProgress(activity: Activity) {
    this.updatePercent.emit([this.activity]);
    this.inputs.activity = this.activity;
  }

  getDisplayPercentage(activity: Activity): number {
    return this.activityManger.getDisplayPercentage(activity);
  }

  resetActivityStatus() {
    this.statusComponent.resetActivityStatus(this.activity.InternalStatus);
  }

  getClassByStatus(): string {
    let classString = '';
    if (this.activity.InternalStatus !== ActivityInternalStatus.Rejected) {
      if (!this.activity.isReadOnly && this.activity.isReady) {
        classString = 'Border-Green';
      }
    } else {
      classString = 'Border-Red';
    }
    return classString;
  }

  getActivityStatusImg(): string {
    const status = this.activity.InternalStatus;
    let img = 'update.svg';
    switch (status) {
      case ActivityInternalStatus.Approved:
        img = 'complete.svg';
        break;
      case ActivityInternalStatus.Rejected:
        img = 'rejected.svg';
        break;
      case ActivityInternalStatus.CantWork:
        img = 'cant-work.svg';
        break;
    }
    img = 'assets/icon/' + img;
    return img;
  }

  navigateTONotes(hierarchyId: number) {
    this.viewToNotesOptions.emit(hierarchyId);
  }

}
