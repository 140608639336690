import { Injectable } from '@angular/core';
import {
  Project,
  WBS,
  Activity,
  UDFCodeValue,
  WBSCategory,
  FlattenActivityCode,
  CrewAssignment,
  Justification,
} from '../../data/EntityIndex';
import {
  ActivityEntityManager,
  WBSEntityManager,
} from '../../data/EntityManagerIndex';
import { Filters, Filter_Shift } from '../../data/Filters';
import { ActivityInternalStatus } from '../../data/ActivityInternalStatus';
import { RoleType } from '../../data/InternalTypes';
import { SyncManager } from '../sync/SyncManager';
import { db } from '../indexDb.service';
import * as moment from 'moment';
import { groupBy, keyBy } from 'src/shared/utility';

export interface IWbsResult {
  Id: number;
  ProjectId: number;
  Name: string;
  GlobalOrder: number;
  wbsCategoryName: string;
  wbsCategoryId: number;
  orderNumber: string;
  Priority: string;
  filteredActivityCount: number;
  filteredActivities: Array<Activity>;
  HasNoContractors?: boolean;
  IsReadOnly?: boolean;
  isSearchFulfilled?: boolean;
  CrewId?: number;
}

export type WorkOrderMap = Record<number, string>;
export type ActivityInternalStatusHelpers = {
  noUpdateIndex: number;
  tempList: ActivityInternalStatus[];
};

@Injectable({
  providedIn: 'root',
})
export class ActivitiesService {
  projectId: number;

  unflattenedHierarchyArray: WBS[];
  fullFlattenedHierarchyArray: WBS[];

  wbsResultMap: Map<number, IWbsResult>;
  allProjectActivities: Activity[];

  constructor(
    private activityProvider: ActivityEntityManager,
    private wbsProvider: WBSEntityManager,
    public sm: SyncManager
  ) {}

  initForProject(projectId: number): void {
    if (this.projectId !== projectId) {
      this.projectId = projectId;
      this.unflattenedHierarchyArray = null;
      this.fullFlattenedHierarchyArray = null;
    }
  }

  async initActivityPageData(params: {
    projectId: number;
    allCategory: boolean;
    noCategory: boolean;
    wbsCategoryId: number;
    role: RoleType;
    crews: CrewAssignment[];
    crewFilter: Filters;
  }): Promise<void> {
    this.initForProject(params.projectId);

    const crewsIds = params.crews.map((c) => c.CrewId);
    const {
      activitiesList,
      wbsList,
      wbsCategoryList,
      flattenActivityCodeList
    } = await db.transaction('r', db.activity, db.wbs, db.wbsCategory, db.flattenActivityCode, async () => {
      let wbsList = await db.wbs
        .where('ProjectId')
        .equals(params.projectId)
        .and((wbs) => {
          if (params.allCategory || params.noCategory) {
            return true;
          }

          return wbs.WBSCategoryId === params.wbsCategoryId;
        })
        .toArray();

      const wbsCategoryList = await db.wbsCategory
        .where('Id')
        .anyOf(wbsList.map((w) => w.WBSCategoryId))
        .toArray();
      const wbsCategoryIdsSet = new Set(wbsCategoryList.map((w) => w.Id));

      if (params.noCategory) {
        wbsList = wbsList.filter((w) => !wbsCategoryIdsSet.has(w.WBSCategoryId));
      }

      const activitiesList = await db.activity
        .where('WbsId')
        .anyOf(wbsList.map((w) => w.Id))
        .and((activity) => (
          activity.Status !== 'Completed' &&
          activity.PercentComplete !== 1 &&
          activity.Type === 'TaskDependent'
        ))
        .sortBy('StartDate');

      const flattenActivityCodeList = await db.flattenActivityCode
        .where('ActivityId')
        .anyOf(activitiesList.map((a) => a.Id))
        .and((fac) => {
          const baseCondition = !fac.TawExcluded;

          if (!params.crewFilter || params.crewFilter !== Filters.MyJobs)  {
            return baseCondition;
          }

          if (params.role === RoleType.CR) {
            return baseCondition && crewsIds.includes(fac.CompanyRepId);
          }

          return baseCondition && (
            crewsIds.includes(fac.MainCrewId) || crewsIds.includes(fac.SupportCrewId)
          );
        }).toArray();

      return {
        activitiesList,
        wbsList,
        wbsCategoryList,
        flattenActivityCodeList,
      };
    });


    const priorities = flattenActivityCodeList.map((fac) => +fac.Priority);
    const minPriority: number = Math.min.apply(null, priorities);

    const crew = params.crews.filter((c) => c.IsDeleted === false)[0];

    this.wbsResultMap = this._prepareWbsResults(
      activitiesList,
      wbsList,
      wbsCategoryList,
      flattenActivityCodeList,
      crew,
      minPriority
    );
  }

  private _prepareWbsResults(
    activitiesList: Partial<Activity>[],
    wbsList: Partial<WBS>[],
    wbsCategoryList: Partial<WBSCategory>[],
    flattenActivityCodeList: Partial<FlattenActivityCode>[],
    crew: CrewAssignment,
    Priority: number,
  ): Map<number, IWbsResult> {
    const wbsMap = keyBy(wbsList, (wbs) => wbs.Id);
    const wbsCategoryMap = keyBy(wbsCategoryList, (wbsCategory) => wbsCategory.Id);
    const facMap = keyBy(flattenActivityCodeList, (fac) => fac.ActivityId);
    const map = new Map<number, IWbsResult>();

    for (const activity of activitiesList) {
      const wbs = wbsMap[activity.WbsId];
      const wbsCategory = wbsCategoryMap[wbs?.WBSCategoryId];
      const fac = facMap[activity.Id];

      if (wbs && fac) {
        let wbsResult = map.get(activity.WbsId);

        if (!wbsResult) {
          wbsResult = {
            Id: activity.WbsId,
            ProjectId: activity.ProjectId,
            Name: wbs.Name,
            filteredActivities: [],
            filteredActivityCount: 0,
            GlobalOrder: wbs.GlobalOrder,
            wbsCategoryName: wbsCategory?.Name,
            wbsCategoryId: wbsCategory?.Id,
            orderNumber: fac?.Ewo,
            IsReadOnly: crew?.IsReadOnly,
            HasNoContractors: crew?.HasNoContractors,
            Priority: Number.isNaN(Priority) ? 'N/A' : Priority.toString(),
          }

          map.set(activity.WbsId, wbsResult);
        }

        wbsResult.filteredActivities.push({
          ...activity,
          activityPriority: fac.Priority,
          Ewo: fac.Ewo,
          CompanyRep: fac.CompanyRep,
          CompanyRepId: fac.CompanyRepId,
          MainCrew: fac.MainCrew,
          MainCrewId: fac.MainCrewId,
          SupportCrew: fac.SupportCrew,
          SupportCrewId: fac.SupportCrewId,
          WorkOrderTask: fac.WorkOrderTask,
          TawExcluded: fac.TawExcluded,
        } as Activity);
        wbsResult.filteredActivityCount = wbsResult.filteredActivities.length;
      }
    }

    return map;
  }

  async getWbsWithActivities(
    shiftFilter: Filter_Shift,
    activityStatus: ActivityInternalStatus[],
    query?: string,
  ): Promise<IWbsResult[]> {
    const result: IWbsResult[] = []

    this.wbsResultMap.forEach((wbs) => {
      const filteredActivities = wbs.filteredActivities.filter((activity) => {
        const isInStatusFilter = this._isActivityInStatus(activity, activityStatus);
        const isInShiftFilter = this._isActivityInCurrentShift(activity, shiftFilter);
        const isInQueryFilter = this.isActivityInQuery(query, activity, wbs);

        return isInStatusFilter && isInShiftFilter && isInQueryFilter;
      });

      if (filteredActivities.length) {
        result.push({
          ...wbs,
          filteredActivities,
          filteredActivityCount: filteredActivities.length,
        });
      }
    });

    result.sort((a, b) => a.GlobalOrder - b.GlobalOrder);

    return result;
  }

  private _isActivityInCurrentShift(
    activity: Partial<Activity>,
    shift: Filter_Shift
  ): boolean {
    if (!shift || shift === Filter_Shift.Shift_All) {
      return true;
    }

    switch (shift) {
      case Filter_Shift.Shift_12:
        return moment(activity.StartDate).isSameOrBefore(
          moment(activity.DataDate).add(0.5, 'd')
        );
      case Filter_Shift.Shift_24:
        return moment(activity.StartDate).isSameOrBefore(
          moment(activity.DataDate).add(1, 'd')
        );
      case Filter_Shift.Shift_48:
        return moment(activity.StartDate).isSameOrBefore(
          moment(activity.DataDate).add(2, 'd')
        );
    }
  }

  private _isActivityInStatus(
    activity: Partial<Activity>,
    statusList: ActivityInternalStatus[]
  ) {
    let ret = false;
    if (
      statusList === undefined ||
      statusList?.length === 0 ||
      (statusList.indexOf(ActivityInternalStatus.NoUpdate) !== -1 &&
        activity.InternalStatus === null) ||
      statusList.indexOf(ActivityInternalStatus[activity.InternalStatus]) !== -1
    ) {
      ret = true;
    }

    return ret;
  }

  isActivityInQuery(
    query: string,
    activity: Partial<Activity>,
    wbs?: Partial<WBS>,
  ): boolean {
    if (!query) {
      return true;
    }

    const lowerCase = query.toLocaleLowerCase();
    return (
      activity.ActivityId?.toLocaleLowerCase().includes(lowerCase) ||
      activity.Name?.toLocaleLowerCase().includes(lowerCase) ||
      activity.Ewo?.toLocaleLowerCase().includes(lowerCase) ||
      wbs?.Name?.toLocaleLowerCase().includes(lowerCase) ||
      wbs?.orderNumber?.toLocaleLowerCase().includes(lowerCase)
    );
  }

  async getWBSActivityProperties(
    wbs: WBS,
    role: RoleType,
    shiftTime: Filter_Shift,
    activityStatus?: ActivityInternalStatus[],
    TCOWbs?: boolean,
    activityQuery?: string
  ): Promise<Activity[]> {
    const activitiesList = this.wbsResultMap.get(wbs.Id)?.filteredActivities;
    const {
      udfList,
      justificationList,
      comments
    } = await this.getActivityDependentLists(activitiesList, TCOWbs);

    let UDFTypeTitle = '';

    if (TCOWbs) {
      const selectedLanguage = localStorage.getItem('language');

      if (selectedLanguage === 'ru') {
        UDFTypeTitle = 'Russian Description';
      } else {
        UDFTypeTitle = 'Kazakh Description';
      }
    }

    const udfMap = keyBy(udfList as UDFCodeValue[], (udf) => udf.ForeignId);
    const justificationMap = keyBy(justificationList as Justification[], (j) => j.ActivityId);
    const commentsGroup = groupBy(comments as Justification[], (c) => c.ActivityId);

    const returnedList = await this.getFilteredActivities({
      wbs,
      activitiesList,
      udfMap,
      justificationMap,
      commentsGroup,
      TCOWbs,
      UDFTypeTitle,
      role,
      activityQuery,
      activityStatus,
      shiftTime
    });

    return returnedList;
  }

  private async getFilteredActivities(params: {
    wbs: WBS,
    activitiesList: Activity[],
    udfMap: Record<number, UDFCodeValue>,
    justificationMap: Record<number, Justification>,
    commentsGroup: Record<number, Justification[]>,
    TCOWbs: boolean,
    UDFTypeTitle: string,
    role: RoleType,
    activityQuery: string,
    activityStatus: ActivityInternalStatus[],
    shiftTime: Filter_Shift
  }): Promise<Activity[]> {
    const {
      wbs,
      activitiesList,
      udfMap,
      justificationMap,
      commentsGroup,
      TCOWbs,
      UDFTypeTitle,
      role,
      activityQuery,
      activityStatus,
      shiftTime
    } = params;

    return activitiesList.reduce((acc: Activity[], activity) => {
      const udf = udfMap[activity.Id];
      const isCommentAvailable = Boolean(justificationMap[activity.Id]);
      let textAndTypeTitle = null;

      if (TCOWbs && udf?.UDFTypeTitle === UDFTypeTitle) {
        textAndTypeTitle = { Text: udf.Text, UDFTypeTitle: udf.UDFTypeTitle };
      }

      const isInQueryFilter = this.isActivityInQuery(activityQuery, activity, wbs);
      const isInStatusFilter = this._isActivityInStatus(activity, activityStatus);
      const isInShiftFilter = this._isActivityInCurrentShift(activity, shiftTime);

      const commentsForActivity = commentsGroup[activity.Id];
      const lastRejectComment = commentsForActivity?.length ? [commentsForActivity[0]] : null;

      if (isInQueryFilter && isInStatusFilter && isInShiftFilter && (!TCOWbs || textAndTypeTitle)) {
        acc.push({
          ...activity,
          isCommentAvailable,
          lastRejectComment,
          HasNoContractors: role === RoleType.CR && wbs.HasNoContractors,
          ...textAndTypeTitle,
        });
      }

      return acc;
    }, []);
  }

  private async getActivityDependentLists(
    activitiesList: Activity[],
    TCOWbs: boolean
  ) {
    const activityIds = activitiesList.map((a) => a.Id);
    let udfList: Partial<UDFCodeValue>[] = [];

    if (TCOWbs) {
      udfList = await db.udfCodeValue
        .where('ForeignId')
        .anyOf(activityIds)
        .toArray();
    }

    const justificationList = await db.justification
      .where('ActivityId')
      .anyOf(activityIds)
      .and((j) => !j.IsDeleted)
      .toArray();

    const comments = await this.activityProvider.getRejectCommentsForActivities(activitiesList);

    return { udfList, justificationList, comments };
  }

  async getFlattenHierarchyArrayWithParent(project: Project): Promise<WBS[]> {
    this.initForProject(project.Id);

    this.unflattenedHierarchyArray = await this.wbsProvider.getHierarchyForEvent(project.Id);
    this.fullFlattenedHierarchyArray = [];

    const hierarchyRoots = this._buildHierarchy(
      this.unflattenedHierarchyArray,
      project.WbsId
    );

    for (const hierarchyRoot of hierarchyRoots) {
      this._flattenHierarchyHierarchy(hierarchyRoot);
    }

    return this.fullFlattenedHierarchyArray;
  }

  getHierarchyPathStringForHierarchy(hierarchy: WBS): string {
    let str = '';
    if (hierarchy) {
      const hierarchyPath: WBS[] = [];

      // get the wbs from the flatten hierarchy list
      const hierarchyInList = this.fullFlattenedHierarchyArray.find(
        (e) => e.Id === hierarchy.Id
      );
      if (hierarchyInList) {
        this._getHierarchyPathForHierarchy(hierarchyInList, hierarchyPath);
        hierarchy.paths = hierarchyPath.slice(2).map((hir) => hir.Name);
        str = hierarchy.paths.join('&emsp;&rarr;&emsp;');
      }

      hierarchy.longPath = str;
    }

    return str;
  }

  private _getHierarchyPathForHierarchy(
    hierarchy: WBS,
    hierarchyPath: WBS[]
  ): WBS[] {
    hierarchyPath.push(hierarchy);
    if (hierarchy.parent) {
      return this._getHierarchyPathForHierarchy(
        hierarchy.parent,
        hierarchyPath
      );
    } else {
      hierarchyPath = hierarchyPath.reverse();
      return hierarchyPath;
    }
  }

  private _flattenHierarchyHierarchy(unflattenedHierarchy: WBS): void {
    if (unflattenedHierarchy) {
      this.fullFlattenedHierarchyArray.push(unflattenedHierarchy);
      const unflattenedChildHierarchyChildren = unflattenedHierarchy.children;
      if (unflattenedChildHierarchyChildren) {
        unflattenedChildHierarchyChildren.forEach((childHierarchy) => {
          childHierarchy.parent = unflattenedHierarchy;
          this._flattenHierarchyHierarchy(childHierarchy);
        });
      }
    }
  }

  private _buildHierarchy(unflattenedHierarchyArray: WBS[], projectWbsId: number): WBS[] {
    // get the root level - project
    const hierarchyRoots: WBS[] = [];

    for (const wbs of unflattenedHierarchyArray) {
      wbs.children = [];

      if (wbs.ParentId === projectWbsId) {
        hierarchyRoots.push(wbs);
      }
    }

    hierarchyRoots.forEach((root) => {
      root.children = this._getNestedChildren(unflattenedHierarchyArray, root.Id);
    });

    return hierarchyRoots;
  }

  private _getNestedChildren(arr: WBS[], parent: number): WBS[] {
    const out: WBS[] = [];
    for (const i in arr) {
      if (arr[i].ParentId === parent) {
        const children = this._getNestedChildren(arr, arr[i].Id);

        if (children.length) {
          arr[i].children = children;
        }
        out.push(arr[i]);
      }
    }
    return out;
  }
}
