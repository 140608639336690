import { SyncConfig } from 'src/services/sync/SyncConfig';
import { IDownloadable, IUploadable } from 'src/services/sync/SyncInterface';
import { Constants } from 'src/shared/constants';
import { MTABaseEntity } from '../entities/MTABaseEntity';
import { SyncLog } from '../entities/SyncLog';
import { db } from 'src/services/indexDb.service';
import * as moment from 'moment';

export class BaseEntityManager {
	protected static instance: BaseEntityManager;
	protected entityType = MTABaseEntity;
	protected entityTableName = '';

	static get Instance(): BaseEntityManager {
		return this.instance || (this.instance = new this());
	}

	isDownloadable(): this is IDownloadable {
		return 'download' in this;
	}

	isUploadable(): this is IUploadable {
		return 'upload' in this;
	}

	async insertData(data: MTABaseEntity[]): Promise<void> {
		//return new Promise<void>(async (resolve, reject) => {
		try {
			const tableName = db.getTableName(this.entityTableName);
			await db.table(tableName).add(data);
			return;
		} catch (e) {
			throw e;
		}
		//});
	}

	async bulkInsertWithQueryRunner(data: MTABaseEntity[] | any): Promise<void> {
		//return new Promise<void>(async (resolve, reject) => {
		try {
			const tableName = db.getTableName(this.entityTableName);
			if (data instanceof Array) {
				const entityArray: any[] = [];
				for (const item of data) {
					const e = new this.entityType();
					e.init(item);
					entityArray.push(e);
				}

				const entityLength = entityArray.length;
				if (entityLength > 0) {
					let entityCountLeft = entityLength;
					do {
						const entityCountToTake = Math.min(
							entityCountLeft,
							Constants.BulkSize
						);
						const sub = entityArray.splice(0, entityCountToTake);

						await db.table(tableName).bulkPut(sub);
						entityCountLeft -= entityCountToTake;
					} while (entityCountLeft > 0);
				}
			} else {
				const e = new this.entityType();
				e.init(data);
				await db.table(tableName).put(e);
			}
			return;
		} catch (e) {
			console.log('bulkInsertWithQueryRunner', { e });
			throw 'Cannot insert data to database.';
		}
		//});
	}

	async insertOrUpdateData(data: any[] | any): Promise<void> {
		//	return new Promise<void>(async (resolve, reject) => {
		try {
			const tableName = db.getTableName(this.entityTableName);

			if (data instanceof Array) {
				const size = 250;
				for (let i = 0; i < data.length; i) {
					let batch: any[];

					if (i + size > data.length) {
						batch = data.slice(i, data.length);
						i = data.length;
					} else {
						batch = data.slice(i, i + size);
						i = i + size;
					}
					await db.table(tableName).bulkPut(batch);
				}
			} else {
				await db.table(tableName).put(data);
			}
			return;
		} catch (e) {
			console.log('insertOrUpdateData', { e });
			throw 'Cannot insert data to database.';
		}
		//	});
	}

	async insertOrUpdateDataWithQueryRunner(data: any[] | any): Promise<void> {
    const tableName = db.getTableName(this.entityTableName);

    if (data instanceof Array) {
      const size = 250;
      for (let i = 0; i < data.length; i) {
        let batch: any[];
        if (i + size > data.length) {
          batch = data.slice(i, data.length);
          i = data.length;
        } else {
          batch = data.slice(i, i + size);
          i = i + size;
        }
        await db.table(tableName).bulkPut(batch);
      }
    } else {
      await db.table(tableName).put(data);
    }
	}

	async updateEntity(entity: any): Promise<any> {
		const tableName = db.getTableName(this.entityTableName);
		const entityItem = new this.entityType();
		entityItem.init({ ...entity });
		const result = await db.table(tableName).put({ ...entityItem }, entity?.Id);
		return result;
	}

	async getDeltaData(config: SyncConfig): Promise<any[] | any> {
		try {
			let entities: any[] = [];
			if (config.lastDownloadTime && config.lastDownloadTime !== '') {
				const tableName = db.getTableName(this.entityTableName);
				entities = await db
					.table(tableName)
					.filter((element) => {
						const isOutToDate = moment(element?.UpdatedDate).isAfter(
							moment(config.lastDownloadTime)
						);
						if (this.entityTableName === 'Activity') {
							return isOutToDate && element?.ProjectId == config.project;
						}
						return isOutToDate;
					})
					.toArray();
			}
			if (entities) {
				return entities;
			} else {
				return null;
			}
		} catch (e) {
			return null;
		}
	}

	async hasOfflineData(syncLogs: SyncLog[]): Promise<Set<number>> {
    if (!syncLogs?.length) {
      return;
    }

    const offlineProjects = new Set<number> ();
    const cai = localStorage.getItem('CAI');

    for (const syncLog of syncLogs) {
      if (syncLog?.LastDownloadTime !== '') {
        const tableName = db.getTableName(this.entityTableName);
        const retData = await db
          .table(tableName)
          .where('ProjectId')
          .equals(syncLog.ProjectId)
          .and((entity) => entity.UpdatedBy === syncLog.UserId || entity.UpdatedBy === cai)
          .and((entity) => moment(entity.UpdatedDate).isAfter(moment(syncLog.LastDownloadTime)))
          .toArray();

        if (retData.length > 0) {
          offlineProjects.add(syncLog.ProjectId);
        }
      }
    }

    return offlineProjects;
	}
}
