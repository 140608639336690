import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, fromEvent, merge, of, Subscription } from 'rxjs';
import { map, retry, timeout } from 'rxjs/operators';
import { environment } from './../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  protected baseURL = environment.BaseAPI;
  networkStatus = navigator.onLine;

  constructor(private http: HttpClient) {}

  async pingServer() {
    if (navigator.onLine) {
      try {
        await firstValueFrom(
          this.http
          .get(`${this.baseURL}/datetime`)
          .pipe(retry(10))
          .pipe(timeout(180000))
        )
        this.networkStatus = true;
        return true;
      } catch (error) {
        this.networkStatus = false;
        return false;
      }
    }

    this.networkStatus = false;
    return false;
  }
}
