import { Injectable } from '@angular/core';
import { WBS } from '../EntityIndex';
import { BaseEntityManager } from './BaseEntityManager';
import { db } from 'src/services/indexDb.service';

@Injectable({
  providedIn:'root'
})
export class WBSEntityManager extends BaseEntityManager {
  entityType = WBS;
	entityTableName = 'wbs';

  async insertWBSData(wbsData: WBS[]) {
    await this.insertData(wbsData);
  }

  async insertOrUpdateWBSData(wbsData: WBS[]) {
    await this.insertOrUpdateData(wbsData);
  }

  async getHierarchyForEvent(eventId: number): Promise<WBS[]> {
    return await db.wbs.where("ProjectId").equals(eventId).sortBy('Id') as WBS[];
  }
}
