import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { HomePage } from '../pages/home/home.page';
import { NoticePage } from '../pages/notice/notice.page';
import { WelcomeSlidesPage } from '../pages/welcome-slides/welcome-slides.page';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
	EventMessage,
	EventType,
	InteractionStatus,
} from '@azure/msal-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, filter, takeUntil } from 'rxjs';
import { User } from 'src/data/EntityIndex';
import { environment } from 'src/environments/environment';
import { UserType } from 'src/data/InternalTypes';
import { LoginProvider } from 'src/shared/login';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
	rootPage: any;
	private readonly _destroying$ = new Subject<void>();

	constructor(
		private readonly _platform: Platform,
		private readonly _translate: TranslateService,
		private readonly _authService: MsalService,
		private readonly _msalBroadcastService: MsalBroadcastService,
		private readonly _http: HttpClient,
		private readonly _loginProvider: LoginProvider
	) {}

	ngOnInit(): void {
		this._initializeApp();
		this._handleRootPage();
		if (this._checkLoginValid()) {
			this._msalBroadcastService.msalSubject$.subscribe(
				(event: EventMessage) => {
					if (event.eventType === EventType.LOGIN_SUCCESS) {
						// A successful login event has occurred
						console.log({ event });
						this._saveUserInfo(
							event.payload['idToken'],
							event.payload['ExpiresOn']
						); // Call your getUser() function here
						this._destroying$.next();
						this._destroying$.complete();
					}
				}
			);
			this._msalBroadcastService.inProgress$
				.pipe(
					filter(
						(status: InteractionStatus) => status === InteractionStatus.None
					),
					takeUntil(this._destroying$)
				)
				.subscribe(() => {
					this._destroying$.next();
					this._destroying$.complete();
					console.log('setLoginDisplay');
				});
		}
	}

	private _checkLoginValid(): boolean {
		console.log('_checkLoginValid');
		// added to satisy edge case where:
		// there's a valid login, but the settingsservice user context disappeared.
		const accountsMsal = this._authService.instance.getAllAccounts();
		console.log({ accountsMsal });
		const user = localStorage.getItem('UserId');
		if (accountsMsal.length > 0 && !user) {
			this._saveUserInfo('', '');
			console.log({ _checkLoginValid: false });
			return false;
		}
		console.log({ _checkLoginValid: true });
		return true;
	}

	//fetch and store user
	private _saveUserInfo(token: string, expiresOn: string) {
		this._getCurrentUser(token).subscribe((user) => {
			localStorage.setItem('UserType', UserType.chevron);
			this._loginProvider.saveMSALToken(token, expiresOn);
			this._loginProvider.saveMSALUser(
				user['LastName'],
				user['FirstName'],
				user['Cai'],
				user['Id']?.toString()
			);
			// this.homeService.userInformationComplete.next(true);
		});
		let accounts = this._authService.instance.getAllAccounts();
		this._authService.instance.setActiveAccount(accounts[0]);
	}

	private _initializeApp(): void {
		this._platform.ready().then(async () => {
			this._translate.addLangs(['en', 'ru', 'kaz']);
			this._translate.setDefaultLang('en');

			const lang = localStorage.getItem('language');
			if (lang) {
				this._translate.use(lang);
			} else {
				localStorage.setItem('language', 'en');
				this._translate.use('en');
			}
		});
	}

	private _handleRootPage(): void {
		if (localStorage.getItem('isNoticeAccepted')) {
			if (this._platform.is('tablet')) {
				this.rootPage = HomePage;
			} else {
				this.rootPage = localStorage.isIntroSkipped
					? HomePage
					: WelcomeSlidesPage;
			}
		} else {
			this.rootPage = NoticePage;
		}
	}

	private _getCurrentUser(adToken: string): Observable<User> {
		const httpOptions = {
			headers: new HttpHeaders()
				.set('Authorization', 'Bearer ' + adToken)
				.set('Content-Type', 'application/json')
				.set('timeout', '180000'),
		};
		return this._http.get<User>(
			environment.BaseAPI + '/user/current',
			httpOptions
		);
	}
}
