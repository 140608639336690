import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
import { WindowRef } from 'src/shared/window-ref';
import { Constants } from './constants';

@Injectable()
export class MTAUtility {
  constructor(
    public winRef: WindowRef
  ) { }

  public static getFormUrlEncoded(toConvert) {
    const formBody = [];
    for (const property in toConvert) {
      if (toConvert.hasOwnProperty(property)) {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(toConvert[property]);
        formBody.push(encodedKey + '=' + encodedValue);
      }
    }
    return formBody.join('&');
  }

  public static formatDate(date: string) {
    const iDate = new Date(date);
    const dateString = iDate.toLocaleDateString() + ', ' + iDate.toLocaleTimeString();
    return dateString;
  }

  public static isToday(date1: string, date2?: string): boolean {
    const d1 = new Date(date1);
    let d2 = null;
    d2 = date2 ? new Date(date2) : new Date();
    d1.setHours(0, 0, 0, 0);
    d2.setHours(0, 0, 0, 0);
    const d1time = d1.getTime();
    const d2time = d2.getTime();
    return d1time - d2time === 0;
  }

  public static getCurrentProjectTimeString(): string {
    return moment().toISOString(); // UTC
  }

  public static getDayOfYear(): number {
    return moment().dayOfYear();
  }

  public static getDateForShift(year: number, dayOfYear: number, time: string): Date {
    const m = moment(time, 'h:mm:ss');
    m.year(year);
    m.dayOfYear(dayOfYear);
    return m.toDate();
  }

  public static isTimeInScope(targetTime: string, startTime: string, endTime: string) {
    const m1 = moment(new Date(targetTime));
    const m2 = moment(startTime, 'h:mm:ss');
    m2.dayOfYear(m1.dayOfYear());
    const m3 = moment(endTime, 'h:mm:ss');
    m3.dayOfYear(m1.dayOfYear());
    if (m3.isSameOrBefore(m2)) {
      m2.add(-1, 'day');
    }
    const ret = m1.isBetween(m2, m3, null, '[]');
    return ret;
  }

  public static getDateByShiftStr(originDate: Date | string, shift: string): string {
    if (originDate) {
      let m: moment.Moment;
      if (typeof originDate === 'object') {
        // Date type for tablet, because p-calendar is used
        m = moment(originDate);
      } else if (typeof originDate === 'string') {
        // string type for ios, because ion-date is used
        m = moment(originDate, 'YYYY-MM-DD');
      }
      if (shift === 'day') {
        m.hour(5); // TODO: with dynamic value from shift table
      } else if (shift === 'night') {
        m.hour(17); // TODO: with dynamic value from shift table
      } else {
        m.hour(0);
      }

      // m is local time
      return m.toDate().toISOString();
    } else {
      return null;
    }
  }

  public static getRoundedMinValue(minValue: number) {
    if (minValue) {
      const roundedValue = parseInt(minValue + '', 10) !== 0 && minValue <= 0.1 ? 10 : Math.trunc(minValue * 10) * 10;
      return roundedValue;
    }
    return 0;
  }

  public static checkAndSetActualStartDateWithHourDelay(startDateString: string, finishDateString: string) {
    const finishMoment = moment(finishDateString);
    const startMoment = startDateString ? moment(startDateString) : moment(finishDateString);
    const duration = moment.duration(finishMoment.diff(startMoment));
    if (duration.asMinutes() <= 2) {
      startMoment.subtract(1, 'hour');
      startDateString = startMoment.toISOString();
    }
    return startDateString;
  }

  /*
    This is the same logic used by the backend
  */
  public static fractionPercentToIntPercent(fractionalPercent: number) {
    const result = Math.round(fractionalPercent * 100);
    return result;
  }

  public static getEncrypted(txt: string): string {
    const key = CryptoJS.enc.Utf8.parse(Constants.pp);
    const iv = CryptoJS.enc.Utf8.parse(Constants.pp);
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(txt), key, {
      keySize: 128 / 8,
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  }

  public static getDecrypted(txt: string): string {
    const byteData = CryptoJS.AES.decrypt(txt, Constants.pp);
    const DecryptedText = byteData.toString(CryptoJS.enc.Utf8);
    return DecryptedText;
  }

  public onlyNumberKey(event) {
    return event.charCode === 8 || event.charCode === 0 ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  public onlyDecimalNumberKey(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  public isNullOrUndefined(obj) {
    if (!obj || obj === '' || obj === 'undefined' || obj === null) {
      return true;
    }
    return false;
  }

  public getFormattedDate() {
    const ts = new Date();
    return (
      ts.getFullYear() +
      '-' +
      this.pad(ts.getMonth() + 1, 2) +
      '-' +
      this.pad(ts.getDate(), 2) +
      'T' +
      this.pad(ts.getHours(), 2) +
      ':' +
      this.pad(ts.getMinutes(), 2) +
      ':' +
      this.pad(ts.getSeconds(), 2)
    );
  }

  public pad(num: number, size: number): string {
    let s = num + '';
    while (s.length < size) {
      s = '0' + s;
    }
    return s;
  }


  disableUWPDefaultBack(): Promise<void> {
    return new Promise<void>(resolve => {
      try {
          const currentView = this.winRef.nativeWindow.Windows.UI.Core.SystemNavigationManager.getForCurrentView();
          currentView.appViewBackButtonVisibility = this.winRef.nativeWindow.Windows.UI.Core.AppViewBackButtonVisibility.collapsed;
        resolve();
      } catch (error) {
        resolve();
      }
    });
  }
}

export function keyBy<T, K extends string | number | symbol>(arr: T[], get: (obj: T) => K): Record<K, T> {
  return arr.reduce((acc, curr) => {
    const key = get(curr);
    acc[key] = curr;
    return acc;
  }, {} as Record<K, T>);
}

export function groupBy<T, K extends string | number | symbol>(arr: T[], get: (obj: T) => K): Record<K, T[]> {
  return arr.reduce((acc, curr) => {
    const key = get(curr);

    if (!acc[key]) {
      acc[key] = [];
    }

    acc[key].push(curr);
    return acc;
  }, {} as Record<K, T[]>);
}

export function toISOLocal(d) {
  const z = n => (n < 10 ? '0' : '') + n;
  let off = d.getTimezoneOffset();
  const sign = off < 0 ? '+' : '-';
  off = Math.abs(off);
  // tslint:disable-next-line:no-bitwise
  return d.getFullYear() + '-' + z(d.getMonth() + 1) + '-' + z(d.getDate()) + 'T' + z(d.getHours()) + ':' +
    z(d.getMinutes()) + ':' + z(d.getSeconds()) + sign + z(off / 60 | 0) + ':' + z(off % 60);
}
