import { ErrorHandler, Injectable } from '@angular/core';
import { AnalyticsProvider } from './analytics/analytics';
import { Guid } from './GUID';
import { LoadingControllerHandler } from './loading-controller-handler';

@Injectable({ providedIn: 'root' })
export class MTAErrorHandler implements ErrorHandler {
  constructor(
    private loadingControllerHandler: LoadingControllerHandler,
    private analytics: AnalyticsProvider
  ) {}

  handleError(error: any) {
    const errorId: Guid = Guid.newGuid();

    if (this.loadingControllerHandler.loadingController) {
      this.loadingControllerHandler.dismissAllLoaders();
    }

    if (!this.loadingControllerHandler.alertController && this.loadingControllerHandler.showAlert) {
      this.loadingControllerHandler.presentAlert(errorId);
    }

    this.loadingControllerHandler.showAlert = true;

    const internalError = {
      subject: 'TA Pro PWA Logs',
      body: error.message,
      date: new Date().toString(),
      errorStack: error.stack
    };
    this.analytics.error('general-error', 'Internal exception', internalError);
  }
}
