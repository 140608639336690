import { AuthService } from './../../services/auth.service';
import { Component, Injector, Input, OnInit } from '@angular/core';
import { CrewType, RoleType, UserType } from './../../data/InternalTypes';
import { NavigationExtras, Router } from '@angular/router';
import {
	ActionSheetController,
	AlertController,
	ModalController,
	NavController,
	PopoverController,
	ToastController,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { FeedbackModalComponent } from 'src/components/feedback-modal/feedback-modal';
import { MTAContainerComponent } from 'src/MTAContainerComponent';
import { PushNotificationProvider } from 'src/providers/push-notification/push-notification';
import { DbService } from 'src/services/db.service';
import { SyncProvider } from 'src/services/sync/sync';
import { SyncProgress } from 'src/services/sync/sync-progress';
import { AppVersionProvider } from 'src/shared/appVersion';
import { LoadingControllerHandler } from 'src/shared/loading-controller-handler';
import { LoginProvider } from 'src/shared/login';
import { SharedPlatformProvider } from 'src/shared/platform';
import { CrewAssignment, Project, Role, User } from '../../data/EntityIndex';
import { Filters } from '../../data/Filters';
import { WelcomeSlidesPage } from '../welcome-slides/welcome-slides.page';
import { HomePagePhoneComponent } from './phone/home.page-phone';
import { HomePageTabletComponent } from './tablet/home.page-tablet';
import { SyncManager } from '../../services/sync/SyncManager';
import { fromEvent, merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NetworkService } from 'src/services/network.service';

@Component({
	selector: 'app-home',
	templateUrl: 'home.page.html',
	styleUrls: ['home.page.scss'],
})
export class HomePage extends MTAContainerComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

	@Input() welcomeMsg: string;
	buildNumber: string;
	hasOfflineData: boolean;
	offlineProjects: Project[];
	isLookAheadBtnDisable = false;
	crewFilter = Filters;
	syncAutomatic = false;
  private syncProgress: SyncProgress;
	user: User;

	constructor(
		public router: Router,
		public injector: Injector,
		public navCtrl: NavController,
		private networkService: NetworkService,
		private actionSheetCtrl: ActionSheetController,
		private toastCtrl: ToastController,
		private alertCtrl: AlertController,
		private modalCtrl: ModalController,
		private popoverCtrl: PopoverController,
		private loadingControllerHandler: LoadingControllerHandler,
		private appVersionProvider: AppVersionProvider,
		private pushManager: PushNotificationProvider,
		private loginProvider: LoginProvider,
		private authService: AuthService,
		public mtaPlatform: SharedPlatformProvider,
		private syncProvider: SyncProvider,
		public dbService: DbService,
		public syncManager: SyncManager,
		private translate: TranslateService
	) {
		super(injector);
		this.actionSheetCtrl = injector.get(ActionSheetController);
		this.syncProvider = injector.get(SyncProvider);

    this.syncProgress = new SyncProgress();
		this.syncProgress.multipleProjectsAssigned = async (projects) => {
			const projectButtons = [];

			projects.forEach((project) => {
				const projectBtn = {
					text: project.ProjectId,
					handler: () => {
						this.enableLanguageSelection(project);
					},
				};

				projectButtons.push(projectBtn);
			});
			await this.pushManager.registerInNotificationHub();
			await this.loadingControllerHandler.dismissAllLoaders();
			const actionSheet = await this.actionSheetCtrl.create({
				header: this.translate.instant('INSTANT.SelectProject'),
				buttons: projectButtons,
			});
			actionSheet.present();
		};
	}

	public ngOnInit() {
    merge(fromEvent(window, 'online'),fromEvent(window, 'offline'))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if (this.syncAutomatic && navigator.onLine) {
          this._syncOfflineData();
        }
      });

		if (!localStorage.getItem('isNoticeAccepted')) {
			this.router.navigate(['notice']);
		}

		this.subComponentJson = {
			tablet: HomePageTabletComponent,
			phone: HomePagePhoneComponent,
		};
		this.subComponent = this.getComponentType();

		this.inputs = {
			crewFilter: this.crewFilter,
			welcomeMsg: this.welcomeMsg,
			isUpdateAvailable: this.appVersionProvider.isUpdateAvailable,
			hasOfflineData: this.hasOfflineData,
			offlineProjects: this.offlineProjects,
			checkIfLookAheadBtnDisable: () => {
				return this.isLookAheadBtnDisable;
			},
			wasUserLoggedin: () => {
				return this.loginProvider.wasUserLoggedin();
			},
			userIsExternal: () => {
				if (this.user !== null && this.user !== undefined) {
					const userType = localStorage.getItem('UserType');
					return (
						this.user.Cai === '' ||
						this.user.Cai === null ||
						this.user.Cai.toLowerCase() === 'null' ||
						userType !== UserType.chevron
					);
				} else {
					return false;
				}
			},
			sync: () => {
				this.sync();
			},
			openSlides: () => {
				this.openSlides();
			},

			setWelcomeMessage: () => {
				this.setWelcomeMessage();
			},

			logout: () => {
				this.logout();
			},

			openJobPage: (type: Filters) => this.openJobPage(type),
			showFeedback: (event) => {
				this.showFeedback(event);
			},
		};

		this.setWelcomeMessage();
		this.outputs = {};
	}

	ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
	}

	ionViewWillEnter() {
		this.user = this.loginProvider.getUser();
		this.setWelcomeMessage();
		this.syncProvider.hasOfflineData().then(async (offlineProjects) => {
			this.offlineProjects = await this.dbService.getProjectsByIds(offlineProjects);

			this.hasOfflineData = Boolean(offlineProjects?.size);
			this.inputs.hasOfflineData = this.hasOfflineData;
			this.inputs.offlineProjects = this.offlineProjects;
		});

		this.checkProjects();
	}

	private async _syncOfflineData(): Promise<void> {
		const ping = await this.networkService.pingServer();

		if (ping) {
			this.syncAutomatic = false;
			this.offlineProjects?.forEach((project) => {
				this._syncProject(project.Id)
      });
    }
	}

	async sync(projectId?: number) {
		if (navigator.onLine) {
			this._syncProject(projectId);
		} else {
      this._promptSyncWhenOnline();
		}
	}

	private async _promptSyncWhenOnline(): Promise<void> {
		this.loadingControllerHandler.dismissAllLoaders();
		const alert = await this.alertCtrl.create({
			header: 'Network Not Available',
			message: 'Do you Like automatic sync, when network is available',
			buttons: [
				{
					text: this.translate.instant('INSTANT.Cancel'),
					role: 'cancel',
					handler: () => {
						this.syncAutomatic = false;
					},
				},
				{
					text: 'OK',
					handler: () => {
						this.syncAutomatic = true;
					},
				},
			],
		});
		alert.present();
	}

	private async _syncProject(projectId?: number): Promise<void> {
    try {
      await this.syncProvider.sync(this.syncProgress, projectId);
      await this.checkProjects();
      this._evalProjects(projectId);
      this.inputs.isUpdateAvailable = this.appVersionProvider.isUpdateAvailable;
    } finally {
      await this.loadingControllerHandler.dismissAllLoaders();
    }
	}

  private _evalProjects(projectId: number): void {
		if (this.offlineProjects?.length) {
			this.offlineProjects.forEach((q, Index) => {
				if (q.Id === projectId) {
					this.offlineProjects.splice(Index, 1);
				}
			});

			if (this.offlineProjects.length > 0) {
				this.hasOfflineData = true;
				this.inputs.hasOfflineData = this.hasOfflineData;
				this.inputs.offlineProjects = this.offlineProjects;
			} else {
				this.hasOfflineData = false;
				this.inputs.hasOfflineData = null;
				this.inputs.offlineProjects = null;
			}
		}
	}

	enableLanguageSelection(project) {
		localStorage.setItem('LanguageSelectionEnabled', 'true');
		this.sync(project.Id);
	}

	async openJobPage(crewFilter: Filters) {
		await this.loadingControllerHandler.createLoading({
			spinner: 'lines',
			mode: 'ios',
		});
		await this.loadingControllerHandler.present();
		const projects = await this.dbService.getActiveProjects();
		if (projects?.length > 0) {
			if (projects.length === 1) {
				await this.loadingControllerHandler.dismissAllLoaders();
				this.openProject(projects[0], crewFilter);
			} else {
				// more than one active project
				const projectButtons = [];
				projects.forEach((project) => {
					const projectBtn = {
						text: project.ProjectId,
						handler: () => {
							this.openProject(project, crewFilter);
						},
					};
					projectButtons.push(projectBtn);
				});
				const actionSheet = await this.actionSheetCtrl.create({
					header: this.translate.instant('INSTANT.SelectProject'),
					buttons: projectButtons,
				});
				await this.loadingControllerHandler.dismissAllLoaders();
				actionSheet.present();
			}
		} else {
			await this.loadingControllerHandler.dismissAllLoaders();
			this.newToast(
				this.translate.instant('INSTANT.NoProject'),
				'toastInfo',
				3000
			);
		}
	}

	async openSlides() {
		const welcomeModal = await this.modalCtrl.create({
			component: WelcomeSlidesPage,
		});
		welcomeModal.present();
	}

	 setWelcomeMessage() {
		if (!this.user) {
			this.user =  this.authService.getUser();
			console.log('user detail',this.user)
		}

		this.welcomeMsg = this.user ? this.translate.instant('INSTANT.Hello') + this.user.FirstName : '';
		this.inputs.welcomeMsg = this.welcomeMsg;
	}

	async openProject(project: Project, crewFilter: Filters) {
		const uid = parseInt(localStorage.getItem('UserId'), 10);
		await this.loadingControllerHandler.createLoading({
			spinner: 'lines',
      message: this.translate.instant('INSTANT.CheckCrewAssignment'),
			mode: 'ios',
		});
		await this.loadingControllerHandler.present();
		this.syncManager.getCrewAssignment(uid, project.Id).then(async (ca) => {
			if (ca?.length > 0) {
				let isCompanyRep = false;
				let isContractForemen = false;
				let role = RoleType.CF;

				ca.forEach((element) => {
					if (element.Crew.Type === CrewType.CoReps) {
						isCompanyRep = true;
					  role = RoleType.CR;
					} else if (
						element.Crew.Type === CrewType.Crew1 ||
						element.Crew.Type === CrewType.Crew2
					) {
						isContractForemen = true;
					}
				}, []);

				if (isCompanyRep && isContractForemen) {
          this._showSelectRoleAlert(project, crewFilter);
          return;
				}
				await this.loadingControllerHandler.dismissAllLoaders();
				this.openJobPageForRole(project, role, crewFilter);
				return;
			}

			await this.loadingControllerHandler.dismissAllLoaders();
			this.newToast(
				this.translate.instant('INSTANT.NoCrew'),
				'toastInfo',
				3000
			);
		});
	}

	private async _showSelectRoleAlert(project: Project, crewFilter: Filters) {
		const alert = await this.alertCtrl.create({
			cssClass: 'custom-alert',
			header: this.translate.instant('INSTANT.SelectRoleHeader'),
			message: this.translate.instant('INSTANT.SelectRoleMessage'),
			buttons: [
				{
					text: this.translate.instant('INSTANT.CompanyRep'),
					handler: () => {
            this.openJobPageForRole(project, RoleType.CR, crewFilter);
					},
				},
				{
					text: this.translate.instant('INSTANT.ContractForemen'),
					handler: () => {
						this.openJobPageForRole(project, RoleType.CF, crewFilter);
					},
				},
			],
		});
		await this.loadingControllerHandler.dismissAllLoaders();
		alert.present();
	}

	private async newToast(message: string, cssClass: string, duration: number) {
		const toast = await this.toastCtrl.create({
			message,
			duration,
			cssClass,
			position: 'bottom'
		});
		toast.present();
	}

	async openJobPageForRole(project: Project, role: RoleType, crewFilter: Filters) {
		if (!role) {
			return;
		}

    const navigationExtras: NavigationExtras = {
      queryParams: {
        projectId: project.Id,
        role,
        crewFilter,
      },
    };

    this.router.navigate(['wbs-category'], navigationExtras);
	}

	async checkProjects(): Promise<void> {
    this.isLookAheadBtnDisable = true;
		await this.loadingControllerHandler.createLoading({
			spinner: 'lines',
			mode: 'ios',
		});
		await this.loadingControllerHandler.present();

    try {
      const projects = await this.dbService.getActiveProjects();
      if (projects.length === 1) {
        localStorage.setItem('LanguageSelectionEnabled', 'true');
      }

      if (projects.length > 0) {
        this.isLookAheadBtnDisable = false;
      } else {
        this.newToast(
          this.translate.instant('INSTANT.ClicSync'),
          'toastInfo',
          5000
        );
      }
    } finally {
      await this.loadingControllerHandler.loadingController.dismiss();
    }
	}

	async logout() {
		if (this.loginProvider.wasUserLoggedin()) {
			const alert = await this.alertCtrl.create({
				header: this.translate.instant('INSTANT.LogoutHeader'),
				message: this.translate.instant('INSTANT.LogoutMessage'),

				buttons: [
					{
						text: this.translate.instant('INSTANT.Cancel'),
						role: 'cancel',
						handler: () => {},
					},
					{
						text: this.translate.instant('INSTANT.Logout'),
						handler: () => {
							this.loginProvider
								.logOut()
								.then(async () => {
									this.isLookAheadBtnDisable = true;
									this.user = null;
									this.setWelcomeMessage();
									const toast = await this.toastCtrl.create({
										message: this.translate.instant('INSTANT.LogoutComplete'),
										duration: 5000,
									});
									this.dbService.reset();
									toast.present();
								})
								.catch(async (e) => {
									const toast = await this.toastCtrl.create({
										message: this.translate.instant('INSTANT.LogoutFailed'),
										duration: 5000,
									});
									toast.present();
								});
						},
					},
				],
			});
			alert.present();
		}
	}

	async showFeedback(event: Event) {
		const feedbackModal = await this.popoverCtrl.create({
			component: FeedbackModalComponent,
			cssClass: this.mtaPlatform.isMobile()
				? 'feedback-modal-phone'
				: 'feedback-modal-tablet',
			showBackdrop: true,
			event,
			backdropDismiss: true,
		});
		await feedbackModal.present(); // breaking change , changed properties and event
	}
}
